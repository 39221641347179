import React from "react";
import { useAuth } from "../../hooks/context";

export default function LogoutButton() {
  const auth = useAuth();
  return (
    <button onClick={() => auth.logOut()} className="logout-button">
    salir
    </button>
  );
};