import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/context";
import toast from "react-hot-toast";
import { returnMyBook, lendMyBook, lendOutside, deleteBook } from "../../API/books";
import LendForm from "./lend_form";


export default function MyBookCard(props) {
  const auth = useAuth();
  const navigate = useNavigate();
  const [showLend, setShowLend] = useState(false);
  const [borrowState, setBorrowState] = useState(props.book.borrowed);
  const [borrowerName, setBorrowerName] = useState(); 

  useEffect(()=> {
    if (borrowState) {
      if (props.book.borrower) {
        setBorrowerName(props.book.borrower.username);
      } else {
        setBorrowerName(props.book.externo);
      }
    }
  }, [borrowState]);

  const handleEditBtn = (e) => {
    e.preventDefault();
    if (props.book._id) navigate(`/books/edit/${props.book._id}`);
    else {
      toast.error("Error!")
    }

  }

 const handleDeleteBtn = async (e) => {
  e.preventDefault();
  try {
    const response = await toast.promise(deleteBook(auth.token, props.book._id),
    {
      success: "Libro eliminado",
      loading: "Eliminando...",
      error: "No se pudo eliminar"
    });
    if (response.status === 200) {
      props.close();
    }
  } catch (error) {
    
  }
 }

  const handleNewPrestamo = () => {
    setShowLend(true);
  }

  const returnBook = async() => {
    try {
      const response = await returnMyBook(auth.token, props.book._id);
      if (response.status === 200) {
        toast.success("Libro devuelto correctamente");
        setBorrowState(false);
        return;
      }
    } catch (error) {
      test.error("Hubo un error");
    }
  };

  async function lendBook(selectedUserId, selectedUsername) {
    try {
      const response = await lendMyBook(auth.token, props.book._id, selectedUserId);
      if (response.status === 200) {
        setBorrowState(true);
        props.book.borrower = {username: selectedUsername,
          _id: selectedUserId };
        setShowLend(false);
        toast.success("Préstamo realizado correctamente");
      }
      //Ver caso cuando libro ya está prestado
    } catch (error) {
      toast.error("Ocurrió un error");
    }
  }
  async function lendBookOutside(name) {
    try {
      const response = await toast.promise(lendOutside(auth.token, props.book._id, name),
      {
        loading: "Prestando...",
        success: "Préstamo realizado correctamente",
        error: "Ocurrió un error"
      });
      if (response.status === 200) {
        setBorrowState(true);
        props.book.borrower = null;
        props.book.externo = name;
        setShowLend(false);
      }
      
    } catch (error) {
      toast.error("Ocurrió un error");
    }
  }

  const closeLendForm = () => {
    setShowLend(false);
  }

  return (
    <div className={'my-book-container'}>
      <div className="book-card">
        <div className="book-info">
          <h5>{props.book.name}</h5>
          <p>Autor: {props.book.author}</p>
          <p>Editorial: {props.book.editorial}</p>
          {
            borrowState ? 
            ( <>
                <p>Prestado a {borrowerName}</p>
              </>
            ) :
            (
              <>
              <p>No prestado</p>
              </>
            )
          }
        </div>
        <div className="book-actions">
          <button onClick={handleEditBtn}>Editar</button>
          {!borrowState ?
            <button onClick={handleNewPrestamo}>Anotar préstamo</button>
            :
            <button onClick={returnBook}>Anotar devolución</button>
          }
          <button onClick={handleDeleteBtn}>Borrar libro</button>
        </div>
      </div>
      <div className="book-forms">
        {showLend &&
          <LendForm closeForm={closeLendForm} 
            lendBook={lendBook}
            book={props.book}
            lendBookOutside={lendBookOutside}
          />
        }
      </div>
  </div>
  );
}