import { useEffect, useState, useMemo } from "react";
import { useAuth } from "../../hooks/context";
import { filterBooks } from "../../functions/filters";

import BookCard from "../../components/books/book-card";
import FilterMenu from "../../components/filters/filter_menu";
import { getBooks } from "../../API/books";
import './style.css';

export default function BookPage() {
  const auth = useAuth();
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(null);
  const [pageLimits, setPageLimits] = useState({
    hasPrevPage: false,
    hasNextPage: false,
  });

  const [filters, setFilters] = useState({
    name: "",
    author: "",
    editorial: "",
    disponible: ""
  });

  const filteredBooks = useMemo(() => filterBooks(books, filters), [books, filters]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBooks(auth.token, currentPage);
        if (response.status === 200) {
          setBooks(response.data.books);
          setPageLimits(response.data.pageLimits);
          setMaxPage(response.data.totalPages);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage]);
  
  const handlePageChange = (newPage) => { 
    if (newPage > maxPage || newPage < 1) {
      return;
    }
    setCurrentPage(newPage);
  };

  return (
    <div className="books-page-container">
      {
        (loading && !auth.user) ? "Cargando..." :
        <>
        <div className="books-upper-div">
          <h1>Todos los libros:</h1>
          <a href="/books/me">Ver mis libros</a>
        </div>
        <div className="middle-books-container">
          <div className="all-books-lower-div">
          {filteredBooks &&
            filteredBooks.map((book) => (
              <BookCard key={book._id} book={book} user_id={auth.user}/>
            ))
          }
          </div>
          <div className="filter-menu">
            <FilterMenu ete={filters}  updateFilters={setFilters} prestado={false} fixPrestado={()=> console.log("")} />
          </div>
        </div>
        <div className="pagination">
          <button disabled={!pageLimits.hasPrevPage} onClick={() => handlePageChange(currentPage - 1)}>
            <span className="arrow">&#8592;</span>
          </button>
          <span>{currentPage}</span>
          {pageLimits.hasNextPage &&
            <button onClick={() => handlePageChange(currentPage + 1)}>
              <span className="arrow">&#8594;</span>
            </button>
          }
        </div>
      </>
      }
  </div>
  );
}
