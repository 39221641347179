import './style.css';
import Login from '../../components/auth/login';


export default function LoginPage(){

  return (
    <div className='login-div'>
      <Login />
    </div>
  );
}