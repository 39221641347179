import React, { useState} from "react";
import { useNavigate } from "react-router-dom";
import { postRegistration } from "../../API/auth";
import toast from "react-hot-toast";
import './register.css';


export default function Register() {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    password_conf: "",
    username: "",
    phone: "",
    clave_registro: "",
    succesMessage: null,
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const {id, value} = e.target;
    setCredentials(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  function isFormFull(){
    if (credentials.password.length < 6) {
      toast.error("Contraseña debe tener mínimo 6 carácteres");
      return false;
    } else if (credentials.password !== credentials.password_conf) {
      toast.error("Contraseñas deben coincidir");
      return false;
    } else if (credentials.username.length < 4) {
      toast.error("Debe ingresas su nombre completo");
      return false;
    } else {
      return true;
    }
  }

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    toast.error("Ingresó un correo inválido.");
    return false;
  } 

  const submitLogin = async(e) => {
    e.preventDefault();
    if (!isFormFull()) {
      toast.error("Rellene los campos obligatorios del formulario");
      return;
    }
    if (!validateEmail(credentials.email)) { return; }
    try {
      const response = await toast.promise(postRegistration(credentials),
        {
          success: "Se ha registrado exitosamente",
          error: "Ha ocurrido un error",
          loading: "Cargando"
        }
      );
      if (response.status === 200) {
        navigate("/login");
      }
    } catch(error) {
      toast.error("Ocurrió un error");
    }
  }

  return (
      <div className="register-box">
        <div className="register-form">
          <h3>Registro</h3>
          <form action="" method="post" className="form">
            <div className="form__field">
              <input
                onChange={handleChange}
                type="username"
                placeholder="Nombre completo"
                id="username"
              />
            </div>
            <div className="form__field">
              <input
              onChange={handleChange}
              type="email" 
              placeholder="Email"
              id="email" 
              />
            </div>
            <div className="form__field">
              <input
                onChange={handleChange}
                type="text"
                placeholder="Número de teléfono" 
                id="phone"
              />
            </div>
            <div className="form__field">
              <input
                onChange={handleChange}
                type="password"
                placeholder="Contraseña" 
                id="password"
              />
            </div>
            <div className="form__field">
              <input
                onChange={handleChange}
                type="password"
                placeholder="Confirme contraseña" 
                id="password_conf"
              />
            </div>
            <div className="form__field">
              <input
                onChange={handleChange}
                type="password"
                placeholder="Clave de registro"
                id="clave_registro"
              />
            </div>

            <div className="form__field">
              <input onClick={submitLogin} type="submit" value="Registrarse" />
            </div>
          </form>

          <p>
            <a className="log-ref" href="/login">Iniciar Sesión</a>
          </p>
        </div>
      </div>
  );
}