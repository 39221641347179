import { useState } from "react";
import ToggleButton from "../../components/toggle_button/toggle_button";
import "./filter-menu.css";

export default function FilterMenu(props) {

  const [filtros, setFiltros] = useState({
    author: "",
    name: "",
    editorial: "",
    disponible: "",
  });

  const [disponible, setDisponible] = useState(false);
  const [prestadoFilter, setPrestadoFilter] = useState(false);

  const changeVisible = () => {
    if (disponible) {
      setFiltros(prevState => ({
        ...prevState,
        ['disponible']: ""
      }));
    } else {
      setFiltros(prevState => ({
        ...prevState,
        ['disponible']: "true"
      }));
    }
    setDisponible(!disponible);
  }

  const changePrestado = () => {
    setPrestadoFilter(!prestadoFilter);
  }


  const handleChange = (e) => {
    const {id, value} = e.target;
    setFiltros(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (filtros.author !== "" ||
        filtros.editorial !== "" ||
        filtros.name !== "" ||
        filtros.disponible !== "") {
      props.updateFilters(filtros);
    }
    props.fixPrestado(prestadoFilter)
  }

  const handleQuitFilters = (e) => {
    e.preventDefault();
    setFiltros({
      author: "",
      editorial: "",
      name: "",
      disponible: "",
    });
    props.updateFilters({
      author: "",
      editorial: "",
      name: "",
      disponible: ""
    });
    if (disponible) {
      changeVisible();
    }
    if (prestadoFilter) {
      setPrestadoFilter(!prestadoFilter);
    }
  }


  return (
    <div className="filter-menu-container">
      <h3>Filtrar libros:</h3>
      <div className="input-filters">
        <div className="input-menu-item">
          <input placeholder="Nombre"
            id="name"
            onChange={handleChange}
            value={filtros.name}

          />
        </div>
        <div className="input-menu-item">
          <input placeholder="Autor"
            id="author"
            onChange={handleChange}
            value={filtros.author}
          />
        </div>
        <div className="input-menu-item">
          <input placeholder="Editorial"
            id="editorial"
            onChange={handleChange}
            value={filtros.editorial}
          />
        </div>
      </div>
      <div className="toggles-filters">
        {!props.prestado &&
          <div className="toggle-item">
            <p>Disponible</p>
            <ToggleButton bid={"disponible-toggle"} isChecked={disponible}
              toggle={changeVisible} />
          </div>
        }
        {props.prestado &&
          <div className="toggle-item">
            <p>Prestados</p>
            <ToggleButton 
              bid={"prestado-toggle"}
              isChecked={prestadoFilter}
              toggle={changePrestado}
            />
          </div>
        }
      </div>
      <button onClick={handleSubmit}>FILTRAR</button>
      <button className="quitar" onClick={handleQuitFilters}>Quitar filtros</button>
    </div>
  );
}