import Section from '../../components/landing/section';
import './style.css';


export default function Landing() {
  return (
    <div className='landing'>
      <Section />
    </div>
  );
}