function validateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return true;
  }
  return false;
} 


function validName(name) {
  const nameRegex = /^[a-zA-Z\- \d]+$/;
  return nameRegex.test(name);
}

function validPhone(phone) {
  const phoneRegex = /^(\+?56)?(\s?)(0?9)(\s?)[9876543]\d{7}$/;
  return phoneRegex.test(phone);
}

function strictValidName(name) {
  const regex = /^[a-zA-ZáéíóúÁÉÍÓÚüÜ'\u00B4. ]{2,60}$/;
  return regex.test(name);
}

function validYear(year) {
  const yearRegex = /^(?:1\d{3}|20\d{2}|9999)$/;
  return yearRegex.test(year);
}

function validPassword(password) {
  const pwdRegex = /^.{6,}$/;
  return pwdRegex.test(password);
}

function validateBook(book) {
  const nameValidity = (book.name.length > 3);
  const yearValidity = validYear(book.year);
  const authorValidity = (book.name.length > 3);
  const editorialValidity = strictValidName(book.editorial);
  if (!nameValidity || !authorValidity || !editorialValidity) {
    return false;
  }
  if (book.year && !yearValidity) {
    return false;
  }
  return true;
}

export {
         validateEmail,
         validName,
         validYear,
         validateBook,
         strictValidName,
         validPhone,
         validPassword
       };