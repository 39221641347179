import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;
const apiService = axios.create({
    baseURL: API_BASE_URL,
});

function getConf(auth, params = null) {
  const authObj = {
    'headers': {
      'Authorization': `Bearer ${auth}`,
    },
    params
  };

  return authObj
}


async function getUsers(auth) {
  try {
    const config = getConf(auth);
    const response = await apiService.get("/users", config);
    return response;
  } catch (error) {
   throw error; 
  }
}

async function getMyUser(auth, user_id) {
  try {
    const config = getConf(auth);
    const response = await apiService.get(`/users/${user_id}`, config);
    return response;
    
  } catch (error) {
    throw error;
  } 
}

async function updateUser(auth, info) {
  try {
    const config = getConf(auth);
    const response = await apiService.post("/auth/me", info, config);
    return response;
  } catch (error) {
    throw error;
  }
}

async function updateUserPwd(auth, info) {
  try {
    const config = getConf(auth);
    const response = await apiService.post("/auth/pwd", info, config);
    return response;
  } catch (error) {
    throw error;
  }
}

export { getUsers, getMyUser, updateUser, updateUserPwd };