export default function ErrorPage() {

  // If error is available, render error message
  return (
    <div id="error-page">
      <h1>ERROR</h1>
      <p>
        <i>Página no encontrada, vuelva por donde vino, so pena de excomunión.</i>
      </p>
    </div>
  );
}