import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { postLogin } from "../../API/auth";
import { useAuth } from "../../hooks/context";
import gandalf from "../../assets/gandalf.png";
import './login.css';


export default function Login() {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    succesMessage: null,
  });
  
  const auth = useAuth();

  const handleChange = (e) => {
    const {id, value} = e.target;
    setCredentials(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const isFormFull = () => {
    if (credentials.email.length > 4 &&
        credentials.password.length > 4) {
          return true;
    } else {
      return false;
    }
  }

  const submitLogin = async(e) => {
    e.preventDefault();
    if (auth.token) {
      toast.error("Ya ha iniciado sesión.");
      return;
    }
    if (!isFormFull) {
      return;
    } 
    const body = {
      email: credentials.email,
      password: credentials.password
    }

    try {
      const response = await toast.promise(
        postLogin(body),
        {
          pending: 'Cargando',
          success: 'Ha iniciado sesión',
          error: 'No se pudo iniciar sesión'
        }
      );
      if (response?.status === 200) {
        auth.loginAction(response.data.user, response.data.token);
        navigate("/books");
      } 
    } catch (error) {
      if (error.response?.status === 401) {
        toast.error("Credenciales inválidas");
      } else {
        toast.error("Error");
      }
    }
  }

  return (
    <div className="grid align__item">
      <div className="register">
        <img className="gandalf" src={gandalf} alt="login-logo"/>
        <form action="" method="post" className="form">
          <div className="form__field">
            <input
             onChange={handleChange}
             type="email" 
             placeholder="Email"
             id="email" 
            />
          </div>
          <div className="form__field">
            <input
              onChange={handleChange}
              type="password"
              placeholder="Contraseña" 
              id="password"
            />
          </div>
          <div className="form__field">
            <input onClick={submitLogin} type="submit" value="Login" />
          </div>
        </form>
        <p>
          <a className="log-ref" href="/register  ">Registrarse</a>
        </p>
      </div>
    </div>
  );
}