import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

const apiService = axios.create({
    baseURL: API_BASE_URL,
});

const METHODS = {
  "get": "GET",
  "post": "POST",
  "delete": "DELETE",
  "patch": "PATCH"
}

async function myRequest(path, method, authToken = null, bodyAndParams ) {
  try {
    const authObj = {
      'headers': {
        'Authorization': `Bearer ${authToken}`,
      },
      params: bodyAndParams.params
    }
    if (method === METHODS.get) {
      const response = await apiService.get(path, authObj);
      return response;
    } else if (method === METHODS.post) {
      const response = await apiService.post(path, bodyAndParams.body, authObj);
      return response; 
    } else  if (method === METHODS.delete) {
      const response = await apiService.delete(path, authObj);
      return response;
    } else if (method ===METHODS.patch) {
      const response = await apiService.patch(path, bodyAndParams.body, authObj)
      return response;
    } else {
      console.log("other method");
    }
  } catch (error) {
    throw error;
  }
}


async function getBooks(authToken, page = 1,
                        limit = 10) {
  try {
    const params = { page, limit };
    const response = await  myRequest('/books', METHODS.get, authToken, { params, body: null });
    return response;
  } catch (error) {
    throw error;
  }
};

async function getMyBooks(authToken, page=1, limit=10) {
  try {
    const params = { page, limit };
    const response = await myRequest('/books/me', METHODS.get, authToken, { params, body: null });
    return response;
  } catch (error) {
    throw error;
  }
};


async function lendMyBook(authToken, bookId, borrowerId) {
  try {
    const body = { borrowerId, bookId };
    const response = await myRequest('/books/borrow', METHODS.post, authToken, { body, params: null });
    return response;
  } catch (error) {
    throw error;
  }
}

async function lendOutside(authToken, bookId, name) {
  try {
    const body = { externo: name, bookId };
    const response = await myRequest('/books/borrow', METHODS.post,
    authToken, { body, params: null });
    return response;
  } catch (error) {
    throw error;
  }
}


async function returnMyBook(authToken, bookId) {
  try {
    const body = { bookId };
    const response = await myRequest('/books/return', METHODS.post, authToken, { body, params: null });
    return response;
  } catch (error) {
    throw error;
  }
}

async function addBook(authToken, bookInfo) {
  try {
    const response = await myRequest('/books', METHODS.post, authToken, { body: bookInfo, params: null })
    return response;
  } catch (error) {
    throw error;
  } 
}

async function getBook(authToken, book_id) {
  try {
    const response = await myRequest(`/books/edit/${book_id}`, METHODS.get, authToken, { body: null, params: null});
    return response;
  } catch (error) {
    throw error;
  } 
}

async function changeBook(authToken, book) {
  try {
    const response = await myRequest(`/books/edit/${book._id}`,
      METHODS.patch, authToken, { body: {updated_book: book}, params: null });
    return response;
    
  } catch (error) {
    throw error;
  }
}

async function deleteBook(authToken, book_id) {
  try {
    const response = await myRequest(`/books/edit/${book_id}`,
    METHODS.delete,
    authToken, 
    { body: null, params: null });
    return response;
  } catch (error) {
    throw error;
  }
}


export { 
  getBooks,
  getMyBooks,
  lendMyBook,
  returnMyBook,
  addBook,
  getBook,
  changeBook,
  deleteBook,
  lendOutside
};