import "./book_details.css";


export default function BookDetails(props) {
  
  return (
  <div className="background">
    <div className="book-detail-container">
      <button className="close-btn" onClick={props.closeForm} id="close-btn">
        <svg>
          <path d="M15 4.41 13.59 3 9 7.59 4.41 3 3 4.41 7.59 9 3 13.59 4.41 15 9 10.41 13.59 15 15 13.59 10.41 9z"></path>
        </svg>
      </button>
      <div className="book-detail-info">
        <div className="book-name">
          <h3>{props.book.name}</h3>
        </div>
        <div className="book-detail">
          <p>Editorial: </p>
          <div className="detail-box">
            <p>{props.book.editorial}</p>
          </div>
        </div>
        <div className="book-detail">
          <p>Autor:</p>
          <div className="detail-box">
            <p>{props.book.author}</p>
          </div>
        </div>
        <div className="book-detail">
          <p>Año:</p>
          <div className="detail-box">
            <p>{props.book.year}</p>
          </div>
        </div>
        <div className="book-detail">
          <p>ISBN</p>
          <div className="detail-box">
            <p>{props.book.isbn}</p>
          </div>
        </div>
        <br/>
        <div className="owner-box">
          <div className="book-owner">
            <p>Dueño:</p>
            <p className="right">{props.book.owner.username}</p>
          </div>
          <div className="book-owner">
            <p>Email:</p>
            <p className="right">{props.book.owner.email}</p>
          </div>
          <div className="book-owner">
            <p>Teléfono:</p>
            <p className="right">{props.book.owner.phone}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}