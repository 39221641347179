import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/context";
import { validateEmail, validPhone, strictValidName, validPassword } from "../../functions/validations";
import { getMyUser, updateUser, updateUserPwd } from "../../API/users";
import toast from "react-hot-toast";
import "./style.css";


export default function PerfilPage() {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [cambioClave, setCambioClave] = useState(false);
  const [cambioInfo, setCambioInfo] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: "",
    email: "",
    phone: "",
  });

  const [pwdInfo, setPwdInfo] = useState({
    pwd: "",
    newPwd: "",
    confPwd: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMyUser(auth.token, auth.user);
        if (response.status === 200) {
          setUserInfo({
            username: response.data.username,
            email: response.data.email,
            phone: response.data.phone || "",
          });
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const {id, value} = e.target;
    setUserInfo(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handlePwdChange = (e) => {
    const {id, value} = e.target;
    setPwdInfo(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handlePwdSubmit = async(e) => {
    e.preventDefault();
    try {
      if (pwdInfo.pwd.length < 4) { toast.error("Debe ingresas la contraseña antigua"); return false; }
      if (pwdInfo.newPwd !== pwdInfo.confPwd) { toast.error("Contraseña nueva debe coincidir"); return false; }
      if (!validPassword(pwdInfo.newPwd)) { toast.error("Contraseña debe tener más de 5 carácteres"); return false; }
      const response = await toast.promise(updateUserPwd(auth.token, pwdInfo), 
      {
        success: "Contraseña actualizada correctamente",
        loading: "Actualizando...",
        error: "Ocurrió un error"
      });
      if (response.status === 200) {
        setCambioClave(false);
        setPwdInfo({
          pwd: "",
          newPwd: "",
          confPwd: ""
        });
      }
    } catch (error) {
      toast.error("Ocurrió un error");
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      if (!validPhone(userInfo.phone) && userInfo.phone) {
        toast.error("Número de teléfono inválido");
      }
      if (!strictValidName(userInfo.username)) { toast.error("Debe ingresar un nombre válido."); return; }
      if (!validateEmail(userInfo.email)) { toast.error("Ingrese un email válido."); return; }
      const response = await toast.promise(updateUser(auth.token, userInfo), 
      {
        success: "Información actualizada correctamente",
        loading: "Actualizando...",
        error: "Ocurrió un error"
      });
      if (response.status === 200) {
        setCambioInfo(false);
      }
    } catch (error) {
      toast.error(`Ocurrió un error: ${error.message}`);
    }
  }

  return (
    loading ?
    "Cargando..."
    :
    <div className="perfil-page-container">
      {cambioInfo &&
        <div className="info-form-container">
          <h4>Editar información</h4>
          <div className="edit-form-div">
            <input
              type="text"
              placeholder="Nombre de usuario"
              onChange={handleChange}
              value={userInfo.username}
              id="username"
            />
            <input
              type="email"
              placeholder="Correo electrónico"
              onChange={handleChange}
              value={userInfo.email}
              id="email"
            />
            <input
              type="tel"
              placeholder="Teléfono"
              onChange={handleChange}
              value={userInfo.phone}
              id="phone"
            />
            <input
              type="submit"
              value={"Cambiar"}
              onClick={handleSubmit}
            />
            <button onClick={()=>setCambioInfo(false)}>Cancelar</button>
          </div>
        </div>
      }
      {!cambioClave && !cambioInfo &&
        <div className="perfil-information">
          <h4>Información</h4>
          <p>{userInfo.username}</p>
          <p>{userInfo.email}</p>
          <p>{userInfo.phone}</p>
          <button onClick={()=> setCambioInfo(!cambioInfo)}>Editar información</button>
          <button onClick={()=> setCambioClave(true)}>Cambiar clave</button>
        </div>
      }
      {cambioClave &&
        <div className="perfil-actions"> 
          <input
            type="password"
            placeholder="Contraseña antigua"
            id="pwd"
            value={pwdInfo.phone}
            onChange={handlePwdChange}
          />
          <input
            type="password"
            placeholder="Contraseña nueva"
            id="newPwd"
            value={pwdInfo.newPwd}
            onChange={handlePwdChange}
          />
          <input
          type="password"
          placeholder="Confirme la contraseña"
          id="confPwd"
          value={pwdInfo.confPwd}
          onChange={handlePwdChange}
          />
          <input 
            type="submit"
            value={"Cambiar clave"}
            onClick={handlePwdSubmit}
          />
        </div>
      }
    </div>
  );
}