import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/context";
import { validateBook } from "../../functions/validations";
import ToggleButton from "../../components/toggle_button/toggle_button";
import toast from "react-hot-toast";
import { getBook, changeBook } from "../../API/books";
import './style.css';


export default function EditBook() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [book, setBook] = useState({
    'name': '',
    'author': '',
    'editorial': '',
    'year': '',
    'isbn': '',
    'prestable': false,
    'visible': false
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBook(auth.token, id);
        if (response.status === 200) {
          setBook(response.data);
          setLoading(false);
        }
      } catch (error) {
        if (error.response.status === 404) {
          toast.error("Error: no se encontró libro");
        } else {
          toast.error("Ocurrió un error");
        }
      }
    };
    fetchData();
  }, []);

  const volver = () => {
    navigate('/books/me');
  }


  const changeVisible = (e) => {
    changeBoolean('visible', !book.visible);
  }

  const changePrestable = (e) => {
    changeBoolean('prestable', !book.prestable);
  }

  const changeBoolean = (id, valor) => {
    setBook(prevState => ({
      ...prevState,
      [id]: valor
    }));
  };

  const handleChange = (e) => {
    const {id, value} = e.target;
    setBook(prevState => ({
      ...prevState,
      [id]: value
    }));
  };


  const submitEdit = async(e) => {
    e.preventDefault();
    try {
      if (validateBook(book)) {
        const response = await toast.promise(changeBook(auth.token, book),
        {
          success: "Libro actualizado",
          error: "Ocurrió un error",
          loading:"Actualizando libro"
        });
        if (response.status === 200) {
          navigate('/books/me');
        }
      } else {
        toast.error("Rellene los campos obligatorios correctamente.");
      }
    } catch (error) {
      console.error(error.data);
    }
  }

  return (
    loading ? 
    "Cargando" :
    <div>
      <div className="edit-form">
        <div className="input-div">
          <p>Nombre:</p>
          <input onChange={handleChange} type="text"
            placeholder={book.name} 
            id="name"
            value={book.name}
          />
        </div>


        <div className="input-div">
          <p>Autor:</p>
          <input onChange={handleChange} type="text"
            placeholder={book.author} 
            id="author"
            value={book.author}
          />
        </div>

        <div className="input-div">
          <p>Año:</p>
          <input onChange={handleChange} type="text"
            placeholder={book.year} 
            id="year"
            value={book.year}
          />
        </div>

        <div className="input-div">
          <p>Editorial:</p>
          <input onChange={handleChange} type="text"
            placeholder={book.editorial} 
            id="editorial"
            value={book.editorial}
          />
        </div>

        <div className="input-div">
          <p>ISBN:</p>
          <input onChange={handleChange} type="text"
            placeholder={book.isbn} 
            id="isbn"
            value={book.isbn}
          />
        </div>

        <div className="toggle-div">
          <p>Visible:</p>
          <ToggleButton bid="1"  toggle={changeVisible} isChecked={book.visible} />
        </div>

        <div className="toggle-div prestable">
          <p>Prestable:</p>
          <ToggleButton bid="2" toggle={changePrestable} isChecked={book.prestable} />
        </div>
        <input className="editar" onClick={submitEdit} type="submit" value="Editar" />
        <input className="volver" onClick={volver} type="submit" value="Volver" />
      </div>
    </div>

  )
}