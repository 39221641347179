  import { useState, useEffect } from "react";
  import BookDetails from "../details/book_details";
  import "./book-card.css";


  export default function BookCard(props) {
    const [disponible, setDisponible] = useState(false);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [isMine, SetIsMine] = useState(false);
    

    useEffect(() => {
      if (props.book) {
        setDataLoaded(true);
        if (!props.book.borrowed && props.book.prestable) {
          setDisponible(true);
          if (props.user_id === props.book.owner._id) {
            SetIsMine(true);
          }
        } else {
          setDisponible(false);
        }
      } else {
        setDataLoaded(false)
      }
    }, []);

    const hideDetails = () => {
      setShowDetail(false);
    }
    const showDetails = (e) => {
      e.preventDefault();
      setShowDetail(true);
    }

    return (
      isDataLoaded ?    (  
        <div className="book-card-container">
            <div className='book-info'>
              <div className="book-name">
                <h5>{props.book.name}</h5>
              </div>
              <div className="book-details">
                <p>Autor: {props.book.author}</p>
                <p>Editorial: {props.book.editorial}</p>
                <p>Dueño: {props.book.owner.username || "No owner"}</p>
                {disponible ?
                  <p className="disponible-p">Disponible</p>
                  :
                  <p className="no-disponible-p">No disponible</p>
                }
              </div>
            </div>
            <div className='book-actions'>
              {disponible && !isMine && false && <button>Solicitar</button> }
              <button onClick={showDetails}>Detalle</button>
            </div>
            {showDetail &&
              <BookDetails book={props.book} closeForm={hideDetails} />
            }
          </div>
        )  :
        <p>"No se cargó información"</p>
    );
  }