import { Route, Routes } from "react-router-dom";
import { useAuth } from "./hooks/context";
import AuthVerify from "./hooks/auth_verify";
import PrivateRoute from './components/auth/private_routes';

import Landing from "./pages/landing";

import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import PerfilPage from "./pages/mi_perfil";

import ErrorPage from './pages/error';

import BookPage from './pages/books';
import MyBookPage from "./pages/my-book";
import AddBooks from "./pages/add-book";
import EditBook from "./pages/edit_book";

import Layout from './components/layout';

import UserListPage from "./pages/users";


export default function Rutas() {

  const auth = useAuth();

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />} errorElement={<ErrorPage/>}>
          <Route path="/" element={<Landing/>}/>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/register" element={<RegisterPage/>}/>
          <Route element={<PrivateRoute />}>
            <Route path="/users/" element={<UserListPage />}/>
            <Route path="/me" element={<PerfilPage />}/>
            <Route path="/books" element={<BookPage/>}/>
            <Route path="/books/me" element={<MyBookPage />} />
            <Route path="/books/new" element={<AddBooks />} />
            <Route path="/books/edit/:id" element={<EditBook />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
      <AuthVerify logOut={auth.logOut} />
    </>  
  );
}