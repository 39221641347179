function filterBooks(libros, filters, prestado = false) {
  let librosFiltrados = libros
  if (filters.name !== "") {
    librosFiltrados = librosFiltrados.filter(libro => libro.name.toLowerCase().includes(filters.name.toLowerCase()));
  }
  if (filters.editorial !== "") {
    librosFiltrados = librosFiltrados.filter(libro => libro.editorial.toLowerCase().includes(filters.editorial.toLowerCase()));
  }
  if (filters.author !== "") {
    librosFiltrados = librosFiltrados.filter(libro => libro.author.toLowerCase().includes(filters.author.toLowerCase()));
  }
  if (filters.disponible === "true") {
    librosFiltrados = librosFiltrados.filter(libro => !libro.borrowed && libro.prestable);
  }
  if (filters.disponible === "false") {
    librosFiltrados = librosFiltrados.filter(libro => libro.borrowed || !libro.prestable);
  }
  if (prestado) {
    librosFiltrados = librosFiltrados.filter(libro => libro.borrowed);
  }
  return librosFiltrados;
}

export { filterBooks };