import React, { useEffect, useState, useMemo } from "react";
import { filterBooks } from "../../functions/filters";
import { useAuth } from "../../hooks/context";
import toast from "react-hot-toast";
import { getMyBooks } from "../../API/books";
import './style.css';

import MyBookCard from "../../components/my_books/my_book_card";
import FilterMenu from "../../components/filters/filter_menu";


export default function MyBookPage() {
  const auth = useAuth();
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [eliminado, setEliminado] = useState(true);
  const [maxPage, setMaxPage] = useState(null);
  const [pageLimits, setPageLimits] = useState({ 
    hasPrevPage: false,
    hasNextPage: false,
  });

  const [filters, setFilters] = useState({
    name: "",
    author: "",
    editorial: "",
    disponible: ""
  });

  const [prestado, setPrestado] = useState(false);

  const filteredBooks = useMemo(() => filterBooks(books, filters, prestado),
    [books, filters, prestado]);




  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMyBooks(auth.token, currentPage);
        if (response.status === 200) {
          setBooks(response.data.books);
          setPageLimits(response.data.pageLimits);
          setMaxPage(response.data.totalPages);
        }
      } catch (error) {
        if (error.response.status === 404) {
          toast("No se encontró ningún libro.")
        } else {
          toast.error("Ocurrió un error");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, eliminado, prestado]);
  
  // Aquí ver que pasa cuando se elimina libro de última
  const handlePageChange = (newPage) => { 
    if (newPage > maxPage || newPage < 1) {
      return;
    }
    setCurrentPage(newPage);
  };

  const deleteBook = () => {
    setEliminado(!eliminado);
  };

  return (
    <div className="my-books-page-container">
      {
        loading ? 
        <div>

          "Cargando..."
        </div>  :
        <>
        <div className="books-upper-div">
          <h1>Mis libros:</h1>
        </div>
        <div className="my-books-list-container">
          <div className="books-lower-div">
            {filteredBooks &&
              filteredBooks.map((book) => (
                <MyBookCard close={deleteBook} key={book._id} book={book} />
              ))
            }
          </div>
          <div className="my-book-filter-menu-container">
            <FilterMenu
              ete={filters}
              updateFilters={setFilters}
              prestado={true}
              fixPrestado={setPrestado}
            />
          </div>
        </div>
        <div className="pagination">
          <button disabled={!pageLimits.hasPrevPage} onClick={() => handlePageChange(currentPage - 1)}>
            <span className="arrow">&#8592;</span>
          </button>
          <span>{currentPage}</span>
          {pageLimits.hasNextPage &&
            <button onClick={() => handlePageChange(currentPage + 1)}>
              <span className="arrow">&#8594;</span>
            </button>
          }
        </div>
      </>
    }
    </div>
  );
  
}
