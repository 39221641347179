//import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthProvider from './hooks/context';
import Rutas from "./Rutas";
import './App.css';


function App() {
  return (
    <Router>
      <AuthProvider>
        <Rutas />
      </AuthProvider>
    </Router>
  );
}

export default App;
