import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/context';
import { getUsers } from '../../API/users';
import toast from 'react-hot-toast';
import "./style.css";

export default function UserListPage() {
  const auth = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await getUsers(auth.token);
        if (response.status === 200) {
          setUsers(response.data);
        } else {
          toast.error("No se cargó los usuarios.")
        }
      } catch (error) {
        if (error.response.status === 404) {
          toast.error("No hay usuarios.");
        }
      } finally {
        setLoading(false);
      }
    }

    fetchUsers();
  }, []);

  return (
    <div className='user-page'>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="user-list-container">
          <h5>Usuarios:</h5>
          <table className="user-list-table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th>Teléfono</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.phone ? user.phone : "no tiene"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}