import React from "react";
import toast from "react-hot-toast";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/context";

const PrivateRoute = () => {
  const user = useAuth();
  if (!user.token){
    setTimeout(() => {
      toast.error("No tiene autorización, debe hacer login",
      {
        duration: 5000,
        position: 'top-center',
      });
    }, 500);
    return <Navigate to="/login" />;
  } 
  return <Outlet />;
};

export default PrivateRoute;
