import axios from "axios";


const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;


const apiService = axios.create({   
    baseURL: API_BASE_URL,
});


async function getUsers(authToken) {
    try {
        const response = await apiService.get('/users',
        {
            'headers': {
              'Authorization': `Bearer ${authToken}`,
            }
        });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

async function postLogin(body) {
    try {
        const response = await apiService.post('/auth/login',
            {
                password: body.password,
                email: body.email
            }
        );
        return response;
    } catch (error) {
        throw error;   
    }
}

async function postRegistration(body) {
  try {
    const response = await apiService.post('/auth/register', body);
    return response;
  } catch (error) {
    throw error;
  }
}

export { getUsers, postLogin, postRegistration };