import React from "react";
import { useAuth } from "../../hooks/context";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { strictValidName} from "../../functions/validations";
import { getUsers } from "../../API/auth";

export default function LendForm(props) {

  const [outsider, setOutsider] = useState({
    outside: false,
    name: ""
  });
  const [borrowers, setBorrowers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedUsername, setSelectedUsername] = useState('');

  const auth = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUsers(auth.token);
        if (response.status === 200) {
          setBorrowers(response.data);
          setSelectedUser(response.data[0]._id);
          setSelectedUsername(response.data[0].username);
        }
      } catch (error) {
        if (error.response.status === 404) {
          toast.error("No hay usuarios registrados.");
          setOutsider({ outside: true, name: "" });
        } else {
          toast.error("Ha ocurrido un error");
        }
      }
    };
    fetchData();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedUser(event.target.value);
    const user = borrowers.filter(user => {
      return user._id === event.target.value;
    })[0];
    setSelectedUsername(user.username);
  };

  const handleSubmit = async () => {
    if (outsider.outside) {
      if (strictValidName(outsider.name)) {
        await props.lendBookOutside(outsider.name);
      } else {
        toast.error("Nombre inválido.");
      }
    } else {
      await props.lendBook(selectedUser, selectedUsername);
    } 
  }
  const handleChangeOutside = (e) => {
    const {id, value} = e.target;
    setOutsider(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  return (
    <div className="lendBox">
      <h3>Prestar libro a: </h3>
      <button onClick={props.closeForm} id="close-btn">
        <svg>
          <path d="M15 4.41 13.59 3 9 7.59 4.41 3 3 4.41 7.59 9 3 13.59 4.41 15 9 10.41 13.59 15 15 13.59 10.41 9z"></path>
        </svg>
      </button>
      { !outsider.outside ?
        <>
          <select id="userDropdown" value={selectedUser} onChange={handleSelectChange}>
            {borrowers.map((user) => (
              <option key={user._id} value={user._id}>
                {user.username}
              </option>
            ))}
          </select>
        </>
      :
      <input
        className="outside-input"
        onChange={handleChangeOutside}
        type="text"
        placeholder="Nombre completo" 
        id="name"
      />

      }
      <div>
        <button onClick={handleSubmit}>Prestar</button>
      </div>
      {!outsider.outside &&
        <div className="lend-outside">
          <p>¿No está registrado?</p>
          <button onClick={() => setOutsider({outside: true, name: ""})}>anotar</button>
        </div>
      }
  </div>
  )
}