import { Outlet } from "react-router-dom";
import NavBar from "./navbar/navbar";
import Footer from "./landing/footer";


export default function Layout() {
  return (
    <main>
      <NavBar />
      <Outlet />
      <Footer />
    </main>
  );
}