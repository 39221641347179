import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/context";
import LogoutButton from "../auth/logout_button";
import logo from "../../assets/logo.svg";
import './style.css';


export default function NavBar() {
  const auth = useAuth();
  return (
    <nav className="navbar">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="logo-a" href="/"><img className="home-logo" src={logo}/></a>
        </li>

        <li className="nav-item">
          <a href="/books" className="nav-links">Libros</a>
        </li>

        <li className="nav-item">
          <a href="/users" className="nav-links">Usuarios</a>
        </li>

        <li className="nav-item">
          <a href="/books/new" className="nav-links">Añadir libro</a>
        </li>

        <li className="nav-item">
          <a href="/me" className="nav-links">Mi perfil</a>
        </li>

        {auth.token ?
          <li className="nav-item">
            <LogoutButton />
          </li> : 
          <li className="nav-item">
            <a href="/login" className="nav-links">Entrar</a>
          </li>
        }
      </ul>
    </nav>
  );
}