import React, { useState} from "react";
import { useAuth } from "../../hooks/context";
import { addBook } from "../../API/books";
import { validateBook } from "../../functions/validations";
import toast from "react-hot-toast";
import './add_book.css';


export default function BookForm() {
  const auth = useAuth();
  const [bookInfo, setBookInfo] = useState({
    name: "",
    author: "",
    isbn: "",
    year: "",
    editorial: "",
  });

  const handleChange = (e) => {
    const {id, value} = e.target;
    setBookInfo(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  // Añadir validación de frontend
  function isFormFull() {
    const { name, author, editorial } = bookInfo;
    if (name.trim() === "") {
      toast.error("Ingrese el nombre");
      return false;
    } else if (author.trim() === "") {
      toast.error("Ingrese el autor");
      return false;
    } else if (editorial.trim() === "") {
      toast.error("Ingrese la editorial");
      return false;
    }
    if (!validateBook(bookInfo)) {
      toast.error("Ingrese datos válidos.");
      return false;
    }
    return true;
  }

  function validYear() {
    if (bookInfo.year.trim() === "") { return true };
    return  /^\d{4}$/.test(bookInfo.year.trim())
  }
  

  const submitLogin = async(e) => {
    e.preventDefault();
    if (!isFormFull()) {
      toast.error("Rellene los campos obligatorios del formulario");
      return;
    }
    if (!validYear()) {
      toast.error("Ingrese un año válido");
      return;
    }
    try {
      const response = await toast.promise(addBook(auth.token, bookInfo),
        {
          success: "Se añadió correctamente",
          error: "Ha ocurrido un error",
          loading: "Cargando"
        }
      );
      if (response.status === 200) {
        setBookInfo({
          name: "",
          author: "",
          year: "",
          editorial: "",
          
        });
      }
    } catch(error) {
    }
  }

  return (
    <div className="grid_align__item-addbook">
      <h2>Añadir libro</h2>
      <div className="addbook">
        <form action="" method="post" className="form">
          <div className="form__field-addbook">
            <input
              onChange={handleChange}
              type="text"
              placeholder="Nombre"
              id="name"
              value={bookInfo.name || ''}
              autoComplete="false"
            />
          </div>
          <div className="form__field-addbook">
            <input
             onChange={handleChange}
             type="text" 
             placeholder="Autor"
             id="author" 
             value={bookInfo.author || ''}
            />
          </div>
          <div className="form__field-addbook">
            <input
              onChange={handleChange}
              type="text"
              placeholder="Año" 
              id="year"
              value={bookInfo.year || ''}
            />
          </div>
          <div className="form__field-addbook">
            <input
              onChange={handleChange}
              type="text"
              placeholder="Editorial" 
              id="editorial"
              value={bookInfo.editorial || ''}
            />
          </div>
          <div className="form__field-addbook">
            <input
              onChange={handleChange}
              type="text"
              placeholder="ISBN" 
              id="isbn"
              value={bookInfo.isbn || ''}
            />
          </div>
          <div className="form__field-addbook">
            <input onClick={submitLogin} type="submit" value="Añadir libro" />
          </div>
        </form>
      </div>
    </div>
  );
}